.media-container {
  padding: 5px;
  margin-inline: 50px;
  margin-block: 10px;
}
.media-icon {
  height: 80px;
  width: 80px;
  border-radius: 40px;
  padding: 10px;
  background: #000;
}
.head {
  font-size: 40px;
  font-weight: 300;
  color: white !important;
  margin: 20px;
  padding: 0;
}
.media {
  display: flex;
  flex-direction: row;
  width: 'max-content';
  margin-top: 100px;
}

.banner_grid {
  justify-content: center;
  text-align: center;
}
.banner__title {
  font-size: 40px;
  font-weight: 300;
  color: white !important;
  margin-inline: 0;
  padding: 0;
  margin-block: 20px;
}
.banner__text {
  font-size: 16px;
  font-weight: 300;
  color: white !important;
  margin: 0;
  padding-inline: 100px;
}
.whyList {
  color: aliceblue;
  margin-top: 60px;
  margin-bottom: 30px;
  list-style-type: circle;
  font-size: 20px;
  text-align: left;
}
.ListDiv {
  justify-content: center;
  text-align: center;
  margin-inline: 15%;
}
.nav__list__item_2 {
  padding-block: 10px;
  font-weight: bold;
}
.features {
    animation-name: blinking;
    padding: 1px;
  height: 100%;
  position: relative;
}
.featurehead {
  color: purple;
  padding-top: 5px;
}
.banner--left {
  text-align: center;
  margin-block: 60px;
  height: 100%;
}
.banner__text {
  font-size: 16px;
  font-weight: 300;
  color: white !important;
  margin: 0;
}
.featurecard {
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 4rem;
  color: aliceblue;
  background-color:  rgba(92, 3, 65, 0.438);
  border: 1px solid rgba(92, 3, 65, 0.438) !important;
  border-radius: 150px !important;
  box-shadow: 0 4px 30px rgba(61, 61, 61, 0.034);
  background-image: radial-gradient(
      at 0% 0%,
      rgba(255, 50, 146, 0.158) 0,
      transparent 100%
    ),
    radial-gradient(at 50% 50%, rgba(58, 0, 39, 0.404) 0, transparent 100%);
  width: 15rem;
  height: 15rem;
}

.road-body {
  margin-top: 3em;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.table {
  background: #10171d !important;
  position: relative;
}
.HoldandEarn {
  background-color: #040c12;
  color: aliceblue;
  padding-top: 15em;
  padding-bottom: 15em;
}

@media only screen and (max-width: 800px) {
  .media {
    display: flex;
    flex-direction: column;
    width: 100% !important;
  }
  .media-container {
    padding: 5px;
    text-align: center;
    justify-content: center;
  }
  .icon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ListDiv {
    justify-content: center;
    text-align: center;
    margin-inline: 0%;
  }
  .road-body {
    display: flex;
    flex-direction: column;
    align-items: center !important;
  }
  .featurecard {
    margin-top: 30px;
  }
}
