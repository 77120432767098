@import url("https://fonts.googleapis.com/css2?family=Black+Ops+One&family=Blaka&family=Kdam+Thmor+Pro&family=Rambla&display=swap");

.nftcard{
    /* border with 2px violet shade */
    border: 5px solid #7a2d9c;
    /* add shadow to card */
    box-shadow: 0 0 20px #7a2d9c;
    /* add border radius to card */
    border-radius: 25px;
    justify-content: center;
   
}

.nftcard {
  animation-name: blinking;
  border-radius: 10px;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  text-align: center;

}
.heading{
  text-shadow: #B10089 0px 0px 15px;
  color:bisque
}
.card-head{
  color:white;
}
table{
  padding: 150px;
  font-size: 18px;
  color: white !important;
  text-align: center;
  border-right: 2px solid #690151;
  border-left: 2px solid #2b0127;
  background-color: #8100701c !important;
  border: 1px solid rgba(81, 3, 92, 0.438) !important;
  border-radius: 20px !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.034);
  background-image: radial-gradient(at 0% 50%,
      rgba(255, 50, 146, 0.158) 0,
      transparent 100%),
    radial-gradient(at 50% 50%, rgba(0, 0, 0, 0) 0, transparent 100%);
}
.card-title{
  /* add title styling */
  font-size: 1.8em;
  font-weight: bold;
  text-align: center;
  font-family: 'Black Ops One';

}

.HoldEearn{
  min-height: 450px;
}
.card{
  padding: 50px;
  justify-content: center;
  align-items: center;
  color:aliceblue;
  background-color: #8100701c !important;
  border: 1px solid rgba(81, 3, 92, 0.438) !important;
  border-radius: 20px !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.034);
  background-image: radial-gradient(at 0% 0%,
      rgba(255, 50, 146, 0.158) 0,
      transparent 100%),
    radial-gradient(at 50% 50%, rgba(0, 0, 0, 0) 0, transparent 100%);
  margin-left:0px !important;

}
/* on hover zoom in */
.card:hover {
  transform: scale(1.1) !important;
  transition: 0.3s ease-in-out !important;
}

.cardrow{
  justify-content:space-between;
  position: relative;
}
.headingrow{
  color: white !important;
  text-align: center;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  width: 18em !important;
  margin-bottom: 2em;
  /* add shadow effect on border */
  box-shadow: 0 0 20px #e2e0e2; 
}
.rewards{
  padding-top: 0.2em;
  font-size: 1.2em;
  font-weight: bold;
  text-shadow: #855050 0px 0px 25px;
  font-family: 'Kdam Thmor Pro', sans-serif;
}
.secondtable{
  text-align: center;
  margin-bottom: 20px;
}
.exchange{
  font-size: 1em ;
  font-weight: italic;
  text-shadow: #7a2565 0px 0px 25px;
  font-family: 'Roboto', sans-serif;
  padding-top: 0.2em;

}

@keyframes blinking {
  50% {
    border-color: #ff0000;
  }
}
.CardButton{
    margin-block:1em;
    background-color:#5865f2 !important;
    border: #5865f2 !important;
    color: white;
    font-size: 1em !important;
}


/* arrow pointer */
.arrow {
  width: 0; 
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #88017d8f;
  border-bottom: 20px solid #88017d8f;
  /* border-bottom: 30px solid #b103a28f; */
  margin: 0 auto;
  margin-top: 1em;
  margin-bottom: 1em;
}

.MediaContainer{
  justify-content: center;
  align-items: center;

}
/* animation to rotate arrow pointer */
.parent {
	perspective: 500px;	
	justify-content: center;
	align-items: center;
}
.arrow {
	transform: rotateY(45deg);
	animation: rotateAnimation 1s linear infinite;
}

@keyframes rotateAnimation {
	from {transform: rotateY(45deg);}
	to {transform: rotateY(225deg);}
}

.gradient-bg-services
{
  background-color: #0f0e13;
  background-image: radial-gradient(at 0% 0%,
      rgba(16, 15, 21, 0.247) 0,
      transparent 50%),
    radial-gradient(at 50% 100%, hsla(225, 39%, 25%, 1) 0, transparent 50%);
}

.gradient-bg-transactions
{
  background-color: #0f0e13;
  background-image: radial-gradient(at 0% 100%,
      rgba(20, 15, 21, 0.418) 0,
      transparent 50%),
    radial-gradient(at 50% 0%, hsla(225, 39%, 25%, 1) 0, transparent 50%);
}

.gradient-bg-footer
{
  background-color: #0f0e13;
  background-image: radial-gradient(at 0% 100%,
      rgba(20, 15, 21, 0.288) 0,
      transparent 53%),
    radial-gradient(at 50% 150%, rgb(114, 39, 114) 0, transparent 50%);
}

.blue-glassmorphism
{
  background: rgb(39, 51, 89, 0.4);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(0, 0, 0, 0.3);
}

/* white glassmorphism */
.white-glassmorphism
{
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(251, 212, 255, 0.253);
}

.eth-card
{
  background-color: #a099ff;
  background-image: radial-gradient(at 83% 67%,
      rgb(152, 231, 156) 0,
      transparent 58%),
    radial-gradient(at 67% 20%, hsla(357, 94%, 71%, 1) 0, transparent 59%),
    radial-gradient(at 88% 35%, hsla(222, 81%, 65%, 1) 0, transparent 50%),
    radial-gradient(at 31% 91%, hsla(9, 61%, 61%, 1) 0, transparent 52%),
    radial-gradient(at 27% 71%, hsla(336, 91%, 65%, 1) 0, transparent 49%),
    radial-gradient(at 74% 89%, hsla(30, 98%, 65%, 1) 0, transparent 51%),
    radial-gradient(at 53% 75%, hsla(174, 94%, 68%, 1) 0, transparent 45%);
}

.text-gradient
{
  background-color: #fff;
  background-image: radial-gradient(at 100% 60%,
      rgb(0, 0, 0) 0,
      transparent 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}